    <div class="checkbox-container">
        <input 
          type="checkbox" 
          [checked]="rowData.selected" 
          [disabled]="isDisabled" 
          (change)="checkedChangeEvent($event)">
        
      </div>
      


