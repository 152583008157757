import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ssp-endpoint-checkbox',
  templateUrl: './ssp-endpoint-checkbox.component.html',
  styleUrls: ['./ssp-endpoint-checkbox.component.css']
})
export class SspEndpointCheckboxComponent implements OnInit, OnChanges {
  @Input() value: any;
  @Input() rowData: any;
  @Output() mappingTableCheckBoxCheckedEvent = new EventEmitter();
  @Input() isDisabled: any;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() { }

  checkedChangeEvent(event: Event) {
    const input = event.target as HTMLInputElement;
    this.rowData.selected = input.checked;
    
    this.mappingTableCheckBoxCheckedEvent.emit({
      checked: this.rowData.selected,
      status: this.rowData.status,
      id: this.rowData.id,
    });
  }
}